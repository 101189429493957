import { geocodeByPlaceId } from 'react-places-autocomplete';

import { getCountryCodeByName } from './countryAndTimezoneHelper';

const googlePlaceAutocompleteService = window.google ? new window.google.maps.places.AutocompleteService() : null;
const googlePlaceService = window.google
  ? new window.google.maps.places.PlacesService(document.createElement('div'))
  : null;

const handlePlaceResponse = (resolve) => (result, status) => {
  if (status === window.google.maps.places.PlacesServiceStatus.OK) {
    resolve(result);
  } else {
    resolve([]);
  }
};

export const getGooglePlacePredictions = async ({ searchKey, operationCountry }) => {
  if (!googlePlaceAutocompleteService) {
    return [];
  }
  const googleResult = await new Promise((resolve) => {
    const countryCode = getCountryCodeByName(operationCountry);

    const requestParam = {
      input: searchKey,
      componentRestrictions: {
        country: countryCode,
      },
    };

    googlePlaceAutocompleteService.getPlacePredictions(requestParam, handlePlaceResponse(resolve));
  });

  return (googleResult || []).map((address) => ({
    id: address.place_id,
    value: address.description,
    name: address.description,
    description: address.description,
    type: 'Google',
  }));
};

export const getGooglePlacesFromText = async ({ searchKey }) => {
  if (!googlePlaceService) {
    return [];
  }
  const googleResult = await new Promise((resolve) => {
    const requestParam = {
      query: searchKey,
      fields: ['formatted_address', 'name', 'place_id'],
    };

    googlePlaceService.findPlaceFromQuery(requestParam, handlePlaceResponse(resolve));
  });

  return (googleResult || []).map((address) => ({
    id: address.place_id,
    value: address.formatted_address,
    name: address.name,
    description: address.name + ' (' + address.formatted_address + ')',
    type: 'Google',
  }));
};

export const geocodeGooglePlaceId = (googlePlaceId) => {
  return geocodeByPlaceId(googlePlaceId)
    .then((results) => results[0])
    .then((place) => {
      const details = { id: place.place_id };

      if (place?.address_components?.length) {
        place.address_components.forEach((addressComponent) => {
          if (addressComponent.types.includes('postal_code')) {
            details.postalCode = addressComponent.long_name;
          } else if (addressComponent.types.includes('country')) {
            details.country = addressComponent.long_name;
          } else if (addressComponent.types.includes('locality')) {
            details.city = addressComponent.long_name;
          } else if (addressComponent.types.includes('administrative_area_level_1')) {
            details.state = addressComponent.long_name;
          }
        });
      }

      const location = place.geometry.location;
      if (location.lat()) {
        details.lat = location.lat();
      }
      if (location.lng()) {
        details.lng = location.lng();
      }
      return details;
    })
    .catch((error) => {
      console.log(error);
    });
};
